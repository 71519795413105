import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Popup, Button, confirm, setOptions, Input, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/dashboardItems.css';
import dayjs from "dayjs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark, faArrowDownWideShort, faArrowUpShortWide, faPipe} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";
import Truncate from "../utils/Truncate";
import IconFile from "../utils/IconFile";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardItems({Dialog, dialog, locale, width, setMyItem, setWorkspace, teamtalk, navigation}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [items, setItems] = useState([]);
    const [results, setResults] = useState([]);
    const [text, setText] = useState(null);
    const [sort, setSort] = useState(true);
    const [instance, setInstance] = useState({});
    const [target, setTarget] = useState(null);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Items = () => {

        const message = {
            type: 'wss',
            path: 'items',
            action: 'get',
            data: {}
        };
        wsCall(message);
    }

    const ButtonSort = () => {
        if (sort && results.length !== 0) {
            const array = results;
            array.sort((a, b) => Number(a.id) - Number(b.id));
            setResults(array);
        } else if (!sort && results.length !== 0) {
            const array = results;
            array.sort((a, b) => Number(b.id) - Number(a.id));
            setResults(array);
        }
        if (sort && items.length !== 0) {
            const array = items;
            array.sort((a, b) => Number(a.id) - Number(b.id));
            setItems(array);
        } else if (!sort && items.length !== 0) {
            const array = items;
            array.sort((a, b) => Number(b.id) - Number(a.id));
            setItems(array);
        }
        if (sort) {
            setSort(false);
        } else {
            setSort(true);
        }
    }

    const ListItem = data => {
        const itemDate = dayjs(parseInt(data.item.id)).format("YYYY-M-D");

        return <li data-id={data.item.id} >
            <div className="mbsc-row cs-dashboard-items-list" >
                {IconFile(data.item)}
                <div className="cs-dashboard-items-list-section">
                    <div className="mbsc-bold mbsc-txt-muted">{width < 992 ? Truncate(data.item.name, 15) : Truncate(data.item.name, 30)}</div>
                    <div className="mbsc-row mbsc-txt-s cs-dashboard-items-item-txt">
                        <span className="mbsc-txt-muted">{data.item.subtitle}</span>
                        <FontAwesomeIcon className="cs-dashboard-items-pipe" icon={faPipe} />
                        <span className="mbsc-txt-muted">{itemDate}</span>
                    </div>
                </div>
            </div>
        </li>;
    }

    const ListTap = data => {
        setTarget(results[data.index].id);
    }

    const InputSuggestion = data => {
        setText(data);
        const itemValue = data;
        let itemResults = items;

        if (itemValue && itemResults.length !== 0) {
            itemResults = itemResults.filter(item =>
                item.name.toLowerCase().includes(itemValue.toLowerCase())
            );
        }
        if (!itemValue && itemResults.length === 0){
            setResults([]);
        } else {
            setResults(itemResults);
        }
    };

    const DeleteItem = useCallback(data => {
        if (data.items) {
            setItems(data.items);
            setResults(data.items);
        }
    }, []);

    const GetItems = useCallback(data => {
        if (data.items) {
            setItems(data.items);
            setResults(data.items);
        }
    }, []);

    const PutItemWorkspace = useCallback(data => {
        if (data.workspace) {
            setWorkspace(data.workspace);
        }
        if (data.toast) {
            toast({message: locale.dashboardItems.j, color: 'info', display: 'bottom', duration: 1000});
        }
    }, [locale.dashboardItems.j]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'items') {
            GetItems(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetItems, setWsResponse]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'item') {
            DeleteItem(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeleteItem, setWsResponse]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'item-workspace') {
            PutItemWorkspace(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutItemWorkspace, setWsResponse]);

    useEffect(() => {
        if (target) {
            instance.openActions(target, 'left');
        }
    }, [instance, target]);

    useEffect(() => {
        Items();
    }, []);

    return (
        <Popup className="cs-dashboard-items-popup" scrollLock={false} closeOnOverlayClick={false} closeOnEsc={false} fullScreen={true} display="center" maxHeight="700" buttons={[]} isOpen={dialog.view === 'dashboardItems'} onClose={DialogClose} >
            <div className="mbsc-grid cs-dashboard-items-header-col">
                <div className="mbsc-row cs-dashboard-items-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-dashboard-items-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-dashboard-items-header-col mbsc-align-center" : "mbsc-col cs-dashboard-items-header-col"} >
                        <Button className="cs-dashboard-items-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {locale.dashboardItems.b}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-dashboard-items-header-col mbsc-align-right">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-items-close-button" onClick={() => DialogClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-dashboard-items-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            {items && items.length === 0 &&
                <div className="cs-dashboard-items-helper mbsc-align-center" >
                    <p>{locale.dashboardItems.d}</p>
                </div>
            }
            {items && items.length !== 0 &&
                <div className="mbsc-grid cs-dashboard-items-header-col">
                    <div className="mbsc-row cs-dashboard-items-header-row">
                        <div className="mbsc-col cs-dashboard-items-header-col cs-dashboard-items-select">
                            <Input
                                inputStyle="underline"
                                placeholder={locale.dashboardItems.e}
                                labelStyle="floating"
                                type="text"
                                name="search"
                                value={text}
                                onChange={ev => InputSuggestion(ev.target.value)}
                            />
                        </div>
                        <div className="mbsc-col-auto cs-dashboard-items-col-sort">
                            <Button className="mbsc-bold" variant="flat" onClick={() => ButtonSort()} >
                                <FontAwesomeIcon className="cs-dashboard-items-sort-button" icon={sort ? faArrowDownWideShort : faArrowUpShortWide} />
                            </Button>
                        </div>
                    </div>
                </div>
            }
            <div className="cs-dashboard-items-scroll" >
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={ListItem}
                    onItemTap={ListTap}
                    data={results}
                    onInit={(event, inst) => setInstance(inst)}
                    actionsWidth={100}
                    actions={{
                        right: [{
                            color: '#0B7DBB',
                            text: locale.dashboardItems.a,
                            action: (event, inst) => {
                                const itemFile = items[event.index].id;

                                const message = {
                                    type: 'wss',
                                    path: 'item-workspace',
                                    action: 'put',
                                    data: {
                                        id: itemFile,
                                        teamtalk:  (!Empty(teamtalk) && teamtalk.id) ? {id: teamtalk.id, users: teamtalk.users} : null,
                                        tab: (!Empty(teamtalk) && teamtalk.id) ? null : navigation.tab
                                    }
                                };
                                wsCall(message);

                                return false;
                            }
                        }, {
                            color: '#0B7DBB',
                            text: locale.dashboardItems.c,
                            action: (event, inst) => {

                                const myItem = items[event.index];
                                setMyItem(myItem);
                                Dialog({view: 'dashboardItemsRename'});

                                inst.close(event.target);

                                return false;
                            }
                        }, {
                            color: '#0B7DBB',
                            text: locale.dashboardItems.i,
                            action: (event, inst) => {
                                confirm({
                                    title: locale.dashboardItems.g,
                                    message: locale.dashboardItems.h,
                                    okText: locale.dashboardItems.i,
                                    cancelText: locale.dashboardItems.f,
                                    callback: (res) => {
                                        if (res) {
                                            const itemFile = items[event.index].id;

                                            const message = {
                                                type: 'wss',
                                                path: 'item',
                                                action: 'delete',
                                                data: {
                                                    item: itemFile
                                                }
                                            };
                                            wsCall(message);
                                        } else {
                                            inst.close(event.target);
                                        }
                                    }
                                });
                                return false;
                            }
                        }]
                    }}
                />
            </div>
        </Popup>
    );
}

export default DashboardItems;
