import React, {useCallback, useEffect, useContext, useState} from 'react';
import {Popup, Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderTeamtalk.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUsersRays, faPlus} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";
import Loading from "../utils/Loading";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderTeamtalk({Dialog, dialog, locale, setTeamtalk}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [conversations, setConversations] = useState([]);
    const [loading, setLoading] = useState(true);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Teamtalk = () => {

        const message = {
            type: 'wss',
            path: 'teamtalk-conversations',
            action: 'get',
            data: {}
        };
        wsCall(message);
    };

    const ButtonTeamTalk = data => {
        setTeamtalk(data);

        const message = {
            type: 'wss',
            path: 'teamtalk-conversation',
            action: 'get',
            data: {
                teamtalk: data
            }
        };
        wsCall(message);
        DialogClose();
    };

    const ListItem = data => {
        let item = '';

        if (data.item.value === 'new') {
            item = <li key={data.item.value} className="cs-dashboard-header-teamtalk-li">
                <Button color="primary" variant="flat" className="cs-dashboard-header-teamtalk-button mbsc-bold" onClick={() => Dialog({view: 'dashboardHeaderTeamtalkNew'})} >
                    <FontAwesomeIcon className="cs-dashboard-header-teamtalk-icon" icon={faPlus} /> {data.item.text}
                </Button>
            </li>;
        } else {
            item = <li key={data.item.value} className="cs-dashboard-header-teamtalk-li">
                <Button color="primary" variant="flat" className="cs-dashboard-header-teamtalk-button mbsc-bold" onClick={() =>ButtonTeamTalk(data.item.value)} >
                    <FontAwesomeIcon className="cs-dashboard-header-teamtalk-icon" icon={faUsersRays} /> {data.item.text}
                </Button>
            </li>;
        }

        return item;
    }

    const GetTeamtalkConversations = useCallback(data => {
        if (data.conversations) {
            data.conversations.push({value: 'new', text: locale.dashboardHeaderTeamtalk.a})
            setConversations(data.conversations);
        }
        setLoading(false);
    }, [locale.dashboardHeaderTeamtalk.a]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'teamtalk-conversations') {
            GetTeamtalkConversations(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetTeamtalkConversations, setWsResponse]);

    useEffect(() => {
        Teamtalk();
    }, []);

    return (
        <Popup className="cs-dashboard-header-teamtalk-popup" display="anchored" anchor={document.getElementById('dashboardHeaderTeamtalk')} showArrow={false} buttons={[]} isOpen={dialog.view === 'dashboardHeaderTeamtalk'} onClose={DialogClose} >
            {loading ?
                <Loading />
                :
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={ListItem}
                    data={conversations}
                />
            }
        </Popup>
    );
}

export default DashboardHeaderTeamtalk;
