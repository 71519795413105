import React, {useCallback, useContext, useState} from 'react';
import {Popup, Input, toast, setOptions, Button, Textarea} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardWorkspaceTodoEdit.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import {NetworkContext} from "../utils/NetworkContext";
import Empty from "../utils/Empty";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardWorkspaceTodoEdit({locale, dialog, Dialog, width, todo, workspaceItem, teamtalk}) {
    const {wsCall} = useContext(NetworkContext);
    const [name, setName] = useState(todo.name);
    const [detail, setDetail] = useState(todo.detail);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ItemSave = () => {
        if (!name) {
            toast({message: locale.dashboardWorkspaceTodoEdit.c, color: 'danger', display: 'bottom', duration: 3000});
        } else if (name) {
            const itemTodo = todo;
            itemTodo.name = name;
            itemTodo.detail = detail;

            const message = {
                type: 'wss',
                path: 'todo',
                action: 'update',
                data: {
                    id: workspaceItem.id,
                    todo: itemTodo,
                    teamtalk:  (!Empty(teamtalk) && teamtalk.id) ? {id: teamtalk.id, users: teamtalk.users} : null
                }
            };
            wsCall(message);
        }
    }

    const ButtonClose = () => {
        if (name !== todo.name || detail !== todo.detail) {
            ItemSave();
        } else {
            DialogClose();
        }
    }

    return (
        <Popup className="cs-dashboard-workspace-todo-edit-popup" width={600} display="center" scrollLock={true} closeOnOverlayClick={false} closeOnEsc={false} buttons={[]} isOpen={dialog.view === 'dashboardWorkspaceTodoEdit'} onClose={DialogClose}>
            <div className="mbsc-grid cs-dashboard-workspace-todo-edit-header-col">
                <div className="mbsc-row cs-dashboard-workspace-todo-edit-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-dashboard-workspace-todo-edit-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-dashboard-workspace-todo-edit-header-col mbsc-align-center" : "mbsc-col cs-dashboard-workspace-todo-edit-header-col"} >
                        <Button className="cs-dashboard-workspace-todo-edit-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {locale.dashboardWorkspaceTodoEdit.e}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-dashboard-workspace-todo-edit-header-col mbsc-align-right">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-workspace-todo-edit-close-button" onClick={() => ButtonClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-dashboard-workspace-todo-edit-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <Input inputStyle="underline" label={locale.dashboardWorkspaceTodoEdit.b} labelStyle="floating" type="text" name="name" value={name} onChange={ev => setName(ev.target.value)} />
            <Textarea
                inputStyle="underline"
                labelStyle="floating"
                name="detail"
                label={locale.dashboardWorkspaceTodoEdit.d}
                rows={4}
                value={detail}
                onChange={(ev) => setDetail(ev.target.value)}
            />
        </Popup>
    );
}

export default DashboardWorkspaceTodoEdit;
