import {useRef, useState} from 'react';
import useWebSocket from "react-use-websocket";
import {toast, snackbar} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import Decode from './Decode';

function Websockets(locale, token, session, wss) {
    const [wsResponse, setWsResponse] = useState(null);
    const enabled = useRef(true);
    const time = useRef(null);
    const {sendJsonMessage} = useWebSocket(wss ? Decode(wss) : null, {
        onOpen: () => Open(),
        onClose: () => Close(),
        onError: () => Error(),
        onMessage: (event) => Message(event),
        heartbeat: {
            message: (() => Heartbeat()),
            returnMessage: JSON.stringify({type: 'heartbeat'}),
            timeout: 60000,
            interval: 30000,
        },
        retryOnError: false,
        share: true
    }, enabled.current);

    const Heartbeat = () => {
        const datetime = Date.now();
        const timeout = datetime + 29000;

        if (time.current && datetime > time.current) {
            time.current = null;
            enabled.current = false;
            Reconnect();
        } else {
            time.current = timeout;
        }
        return JSON.stringify({type: 'heartbeat', token: token});
    }

    const wsCall = data => {
        const item = data;
        item.token = token;
        item.session = session;

        sendJsonMessage(data);
    };

    const Open = () => {
        toast({message: locale.websocket.c, color: 'info', duration: 1000, display: 'bottom'});

        const item = {
            token: token,
            type: 'heartbeat'
        }
        sendJsonMessage(item);
    }

    const Close = () => {
        if (wss) {
            if (enabled.current) {
                enabled.current = false;
            }
            Reconnect();
        } else {
            toast({message: locale.websocket.a, color: 'info', duration: 1000, display: 'bottom'});
        }
    }

    const Error = () => {}

    const Message = data => {
        const message = JSON.parse(data.data);

        if (message.type === 'heartbeat') {
            time.current = null;
        } else {
            setWsResponse(message);
        }
    }

    const Reconnect = () => {
        snackbar({
            message: locale.websocket.b,
            button: {
                text: locale.websocket.d,
                action: function () {

                    enabled.current = true;
                }
            },
            color: 'info',
            duration: false,
            display: 'center'
        });
    }

    return {wsResponse, wsCall, setWsResponse};
}

export default Websockets;
