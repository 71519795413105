import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Button, Popup, Select, setOptions, Input} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardQuestionUsers.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";
import Loading from "../utils/Loading";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardQuestionUsers({Dialog, dialog, locale, setToast, width, teamtalk, setTeamtalk}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [users, setUsers] = useState([]);
    const [mobiUsers, setMobiUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [results, setResults] = useState([]);
    const [text, setText] = useState(null);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Users = () => {

        const message = {
            type: 'wss',
            path: 'teamtalk-users',
            action: 'get',
            data: {
                users: teamtalk.users
            }
        };
        wsCall(message);
    };

    const ButtonClose = () => {
        DialogClose();
    }

    const SelectUsers = data => {
        setUsers(data);

        const message = {
            type: 'wss',
            path: 'teamtalk-conversation-user',
            action: 'update',
            data: {
                users: data,
                mobiUsers: mobiUsers,
                teamtalk: {id: teamtalk.id, users: teamtalk.users}
            }
        };
        wsCall(message);
    }

    const InputSuggestion = data => {
        setText(data);
        const itemValue = data;
        let itemResults = mobiUsers;

        if (itemValue && itemResults.length !== 0) {
            itemResults = itemResults.filter(item =>
                item.text.toLowerCase().includes(itemValue.toLowerCase())
            );
        }
        if (!itemValue && itemResults.length === 0){
            setResults([]);
        } else {
            setResults(itemResults);
        }
    };

    const SelectRows = () => {
        if (text && results.length === 0) {
            return 3;
        } else if (text && results.length !== 0 && results.length < 10) {
            return results.length;
        } else if (text && results.length !== 0 && results.length > 10) {
            return 10;
        } else if (!text && mobiUsers.length > 10) {
            return 10;
        } else if (!text && mobiUsers.length < 10) {
            return mobiUsers.length;
        }
    }

    const GetTeamtalkUsers = useCallback(data => {
        if (data.users) {
            setUsers(data.users);
        }
        if (data.mobiUsers) {
            setMobiUsers(data.mobiUsers);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        if (wsResponse && wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'teamtalk-users') {
            GetTeamtalkUsers(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetTeamtalkUsers, setWsResponse]);

    useEffect(() => {
        Users();
    }, []);

    return (
        <Popup className="cs-dashboard-question-users-popup" maxHeight={750} closeOnOverlayClick={false} closeOnEsc={false} scrollLock={false} display="center" width="600" buttons={[]} isOpen={dialog.view === 'dashboardQuestionUsers'} onClose={DialogClose}>
            <div className="mbsc-grid cs-dashboard-question-users-header-col">
                <div className="mbsc-row cs-dashboard-question-users-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-dashboard-question-users-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-dashboard-question-users-header-col mbsc-align-center" : "mbsc-col cs-dashboard-question-users-header-col"} >
                        <Button className="cs-dashboard-question-users-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {locale.dashboardQuestionUsers.c}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-dashboard-question-users-header-col mbsc-align-right">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-question-users-close-button" onClick={() => ButtonClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-dashboard-question-users-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="cs-dashboard-question-users-helper">
                <p>{locale.dashboardQuestionUsers.d}</p>
            </div>
            <Input
                inputStyle="underline"
                placeholder={locale.dashboardHeaderConversations.m}
                labelStyle="floating"
                type="text"
                name="search"
                value={text}
                onChange={ev => InputSuggestion(ev.target.value)}
            />
            {loading ?
                <Loading />
                :
                <Select
                    animation="slide-down"
                    inputStyle="underline"
                    dropdown={false}
                    placeholder={locale.dashboardQuestionUsers.a}
                    labelStyle="floating"
                    touchUi={false}
                    rows={SelectRows()}
                    display="inline"
                    selectMultiple={true}
                    value={users}
                    data={text ? results : mobiUsers}
                    onChange={args => SelectUsers(args.value)}
                />
            }
        </Popup>
    );
}

export default DashboardQuestionUsers;
