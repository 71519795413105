import React, {useCallback, useContext, useState} from 'react';
import {Popup, Input, toast, setOptions, Button, Textarea} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardWorkspaceTodoNew.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import {NetworkContext} from "../utils/NetworkContext";
import Empty from "../utils/Empty";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardWorkspaceTodoNew({locale, dialog, Dialog, width, workspaceItem, teamtalk}) {
    const {wsCall} = useContext(NetworkContext);
    const [name, setName] = useState(null);
    const [detail, setDetail] = useState(null);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ButtonSave = () => {
        if (!name) {
            toast({message: locale.dashboardWorkspaceTodoNew.c, color: 'danger', display: 'bottom', duration: 3000});
        } else if (name) {

            const message = {
                type: 'wss',
                path: 'todo',
                action: 'put',
                data: {
                    id: workspaceItem.id,
                    detail: detail,
                    name: name,
                    teamtalk:  (!Empty(teamtalk) && teamtalk.id) ? {id: teamtalk.id, users: teamtalk.users} : null
                }
            };
            wsCall(message);
        }
    }

    return (
        <Popup className="cs-dashboard-workspace-todo-new-popup" width={600} display="center" scrollLock={true} closeOnOverlayClick={false} closeOnEsc={false} buttons={[]} isOpen={dialog.view === 'dashboardWorkspaceTodoNew'} onClose={DialogClose}>
            <div className="mbsc-grid cs-dashboard-workspace-todo-new-header-col">
                <div className="mbsc-row cs-dashboard-workspace-todo-new-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-dashboard-workspace-todo-new-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-dashboard-workspace-todo-new-header-col mbsc-align-center" : "mbsc-col cs-dashboard-workspace-todo-new-header-col"} >
                        <Button className="cs-dashboard-workspace-todo-new-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {locale.dashboardWorkspaceTodoNew.e}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-dashboard-workspace-todo-new-header-col mbsc-align-right">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-workspace-todo-new-close-button" onClick={() => DialogClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-dashboard-workspace-todo-new-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <Input inputStyle="underline" label={locale.dashboardWorkspaceTodoNew.b} labelStyle="floating" type="text" name="name" value={name} onChange={ev => setName(ev.target.value)} />
            <Textarea
                inputStyle="underline"
                labelStyle="floating"
                name="detail"
                label={locale.dashboardWorkspaceTodoNew.d}
                rows={4}
                value={detail}
                onChange={(ev) => setDetail(ev.target.value)}
            />
            <div className="mbsc-button-group-justified cs-dashboard-workspace-todo-new-nav-buttons">
                <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-workspace-todo-new-nav-button" onClick={() => ButtonSave()} >
                    {locale.dashboardWorkspaceTodoNew.a}
                </Button>
            </div>
        </Popup>
    );
}

export default DashboardWorkspaceTodoNew;
