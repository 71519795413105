import React, {useContext, useState} from 'react';
import {Button, SegmentedGroup, Segmented, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardWorkspaceGraph.css';
import {Doughnut, Bar, Line, PolarArea, Radar, Pie, Bubble, Scatter} from "react-chartjs-2";
import {NetworkContext} from "../utils/NetworkContext";
import Empty from "../utils/Empty";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardWorkspaceGraph({locale, workspaceItem, setLoading, teamtalk, navigation}) {
    const {wsCall} = useContext(NetworkContext);
    const [menu, setMenu] = useState('original');

    const ButtonUpdate = data => {
        setLoading(true);

        const message = {
            type: 'wss',
            path: 'workspace-item',
            action: 'update',
            data: {
                id: workspaceItem.id,
                teamtalk: (!Empty(teamtalk) && teamtalk.id) ? {id: teamtalk.id, users: teamtalk.users} : null,
                tab: (!Empty(teamtalk) && teamtalk.id) ? null : navigation.tab
            }
        };
        if (data === 'accept') {
            message.data.accept = true;
        }
        if (data === 'reject') {
            message.data.reject = true;
        }
        wsCall(message);
    }

    const SegmentedUpdate = data => {
        setMenu(data.target.value);
    }

    return (
        <>
            {workspaceItem.update &&
                <div className="mbsc-row cs-dashboard-workspace-graph-header-secondary" >
                    <div className="mbsc-col" />
                    <div className="mbsc-col-auto" >
                        <div className="mbsc-row cs-dashboard-workspace-graph-col-end">
                            <Button color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-dashboard-workspace-graph-color" onClick={() => ButtonUpdate('accept')} >
                                {locale.dashboardWorkspaceGraph.c}
                            </Button>
                            <Button color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-dashboard-workspace-graph-color" onClick={() => ButtonUpdate('reject')} >
                                {locale.dashboardWorkspaceGraph.d}
                            </Button>
                        </div>
                    </div>
                </div>
            }
            {workspaceItem.update &&
                <div className="mbsc-row mbsc-justify-content-center cs-dashboard-workspace-graph-charts">
                    <div className="mbsc-col-12 mbsc-col-sm-12 mbsc-col-md-10 mbsc-col-lg-8 mbsc-col-xl-6">
                        <SegmentedGroup className="cs-dashboard-workspace-graph-update" name="update" color="primary" value={menu} onChange={SegmentedUpdate}>
                            <Segmented value="original" >
                                {locale.dashboardWorkspaceGraph.a}
                            </Segmented>
                            <Segmented value="update" >
                                {locale.dashboardWorkspaceGraph.b}
                            </Segmented>
                        </SegmentedGroup>
                    </div>
                </div>
            }
            {((!workspaceItem.update && workspaceItem.data.type === 'doughnut') || (workspaceItem.update && menu === 'original' && workspaceItem.data.type === 'doughnut') || (workspaceItem.update && menu === 'update' && workspaceItem.data.update.type === 'doughnut')) &&
                <div className="mbsc-row mbsc-justify-content-center mbsc-padding cs-dashboard-workspace-graph-charts">
                    <div className="mbsc-col-12 mbsc-col-sm-10 mbsc-col-md-8 mbsc-col-lg-6 mbsc-col-xl-4">
                        <Doughnut options={(!workspaceItem.update || (workspaceItem.update && menu === 'original')) ? workspaceItem.data.options : workspaceItem.data.update.options} data={(!workspaceItem.update || (workspaceItem.update && menu === 'original')) ? workspaceItem.data.data : workspaceItem.data.update.data} />
                    </div>
                </div>
            }
            {((!workspaceItem.update && workspaceItem.data.type === 'pie') || (workspaceItem.update && menu === 'original' && workspaceItem.data.type === 'pie') || (workspaceItem.update && menu === 'update' && workspaceItem.data.update.type === 'pie')) &&
                <div className="mbsc-row mbsc-justify-content-center mbsc-padding cs-dashboard-workspace-graph-charts">
                    <div className="mbsc-col-12 mbsc-col-sm-10 mbsc-col-md-8 mbsc-col-lg-6 mbsc-col-xl-4">
                        <Pie options={(!workspaceItem.update || (workspaceItem.update && menu === 'original')) ? workspaceItem.data.options : workspaceItem.data.update.options} data={(!workspaceItem.update || (workspaceItem.update && menu === 'original')) ? workspaceItem.data.data : workspaceItem.data.update.data} />
                    </div>
                </div>
            }
            {((!workspaceItem.update && workspaceItem.data.type === 'polar') || (workspaceItem.update && menu === 'original' && workspaceItem.data.type === 'polar') || (workspaceItem.update && menu === 'update' && workspaceItem.data.update.type === 'polar')) &&
                <div className="mbsc-row mbsc-justify-content-center mbsc-padding cs-dashboard-workspace-graph-charts">
                    <div className="mbsc-col-12 mbsc-col-sm-10 mbsc-col-md-8 mbsc-col-lg-6 mbsc-col-xl-4">
                        <PolarArea options={(!workspaceItem.update || (workspaceItem.update && menu === 'original')) ? workspaceItem.data.options : workspaceItem.data.update.options} data={(!workspaceItem.update || (workspaceItem.update && menu === 'original')) ? workspaceItem.data.data : workspaceItem.data.update.data} />
                    </div>
                </div>
            }
            {((!workspaceItem.update && workspaceItem.data.type === 'radar') || (workspaceItem.update && menu === 'original' && workspaceItem.data.type === 'radar') || (workspaceItem.update && menu === 'update' && workspaceItem.data.update.type === 'radar')) &&
                <div className="mbsc-row mbsc-justify-content-center mbsc-padding cs-dashboard-workspace-graph-charts">
                    <div className="mbsc-col-12 mbsc-col-sm-12 mbsc-col-md-10 mbsc-col-lg-8 mbsc-col-xl-6">
                        <Radar options={(!workspaceItem.update || (workspaceItem.update && menu === 'original')) ? workspaceItem.data.options : workspaceItem.data.update.options} data={(!workspaceItem.update || (workspaceItem.update && menu === 'original')) ? workspaceItem.data.data : workspaceItem.data.update.data} />
                    </div>
                </div>
            }
            {((!workspaceItem.update && workspaceItem.data.type === 'bar') || (workspaceItem.update && menu === 'original' && workspaceItem.data.type === 'bar') || (workspaceItem.update && menu === 'update' && workspaceItem.data.update.type === 'bar')) &&
                <div className="mbsc-row mbsc-justify-content-center mbsc-padding cs-dashboard-workspace-graph-charts">
                    <div className="mbsc-col-12">
                        <Bar options={(!workspaceItem.update || (workspaceItem.update && menu === 'original')) ? workspaceItem.data.options : workspaceItem.data.update.options} data={(!workspaceItem.update || (workspaceItem.update && menu === 'original')) ? workspaceItem.data.data : workspaceItem.data.update.data} />
                    </div>
                </div>
            }
            {((!workspaceItem.update && workspaceItem.data.type === 'line') || (workspaceItem.update && menu === 'original' && workspaceItem.data.type === 'line') || (workspaceItem.update && menu === 'update' && workspaceItem.data.update.type === 'line')) &&
                <div className="mbsc-row mbsc-justify-content-center mbsc-padding cs-dashboard-workspace-graph-charts">
                    <div className="mbsc-col-12">
                        <Line options={(!workspaceItem.update || (workspaceItem.update && menu === 'original')) ? workspaceItem.data.options : workspaceItem.data.update.options} data={(!workspaceItem.update || (workspaceItem.update && menu === 'original')) ? workspaceItem.data.data : workspaceItem.data.update.data} />
                    </div>
                </div>
            }
            {((!workspaceItem.update && workspaceItem.data.type === 'bubble') || (workspaceItem.update && menu === 'original' && workspaceItem.data.type === 'bubble') || (workspaceItem.update && menu === 'update' && workspaceItem.data.update.type === 'bubble')) &&
                <div className="mbsc-row mbsc-justify-content-center mbsc-padding cs-dashboard-workspace-graph-charts">
                    <div className="mbsc-col-12">
                        <Bubble options={(!workspaceItem.update || (workspaceItem.update && menu === 'original')) ? workspaceItem.data.options : workspaceItem.data.update.options} data={(!workspaceItem.update || (workspaceItem.update && menu === 'original')) ? workspaceItem.data.data : workspaceItem.data.update.data} />
                    </div>
                </div>
            }
            {((!workspaceItem.update && workspaceItem.data.type === 'scatter') || (workspaceItem.update && menu === 'original' && workspaceItem.data.type === 'scatter') || (workspaceItem.update && menu === 'update' && workspaceItem.data.update.type === 'scatter')) &&
                <div className="mbsc-row mbsc-justify-content-center mbsc-padding cs-dashboard-workspace-graph-charts">
                    <div className="mbsc-col-12">
                        <Scatter options={(!workspaceItem.update || (workspaceItem.update && menu === 'original')) ? workspaceItem.data.options : workspaceItem.data.update.options} data={(!workspaceItem.update || (workspaceItem.update && menu === 'original')) ? workspaceItem.data.data : workspaceItem.data.update.data} />
                    </div>
                </div>
            }
        </>
    );
}

export default DashboardWorkspaceGraph;
