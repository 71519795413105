import React, {useCallback, useContext, useEffect} from 'react';
import {Button, toast, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import './styles/header.css';
import HeaderAccount from "./dialogs/HeaderAccount";
import Empty from "./utils/Empty";
import Spanish from "./locale/spanish.json";
import English from "./locale/english.json";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLanguage} from "@fortawesome/pro-duotone-svg-icons";
import {NetworkContext} from "./utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function Header({locale, dialog, Dialog, configuration, setLocale, setConfiguration, account, wss, setDialog, setNavigation, width}) {
    const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);

    const Configuration = data => {
        const message = {
            type: 'rest',
            path: 'account-configuration',
            action: 'get',
            data: {
                account: data
            }
        };
        restCall(message);
    }

    const ButtonLocale = () => {
        if (window.localStorage.locale === 'en') {
            window.localStorage.locale = 'es';
            setLocale(Spanish);
        } else if (window.localStorage.locale === 'es') {
            window.localStorage.locale = 'en';
            setLocale(English);
        }
    }

    const GetAccountConfiguration = useCallback(data => {
        if (data.configuration) {
            let item = {...data.configuration};
            item.locale.es = Object.assign(item.locale.es, Spanish);
            item.locale.en = Object.assign(item.locale.en, English);
            setConfiguration(item);
            if (window.localStorage.locale && window.localStorage.locale === 'en') {
                setLocale(item.locale.en);
            } else if (window.localStorage.locale && window.localStorage.locale === 'es') {
                setLocale(item.locale.es);
            }
        }
        if (data.account) {
            account.current = data.account;
            window.localStorage.account = data.account;
        }
        if (data.url) {
            wss.current = data.url;
        }
        if (data.dialog) {
            setDialog(data.dialog);
        }
        if (data.navigation) {
            setNavigation(data.navigation);
        }
        if (data.error) {
            toast({message: locale.header.a, color: 'danger', display: 'bottom', duration: 3000});
        }
    }, [locale.header.a]);

    useEffect(() => {
        if (!wss.current && restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'account-configuration') {
            GetAccountConfiguration(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetAccountConfiguration, setRestResponse]);

    useEffect(() => {
        if (!account.current) {
            Dialog({view: 'headerAccount'});
        } else if (account.current && !wss.current) {
            Configuration(account.current);
        }
    }, []);

    return (
        <div className="mbsc-grid cs-header-toolbar">
            <div className="mbsc-row">
                <div className="mbsc-col" >
                    <div className="mbsc-row cs-header-col-start">
                        <div style={configuration.logo ? {backgroundImage: `url(${configuration.logo})`} : {backgroundImage: `url(${configuration.logoCS})`}} className="cs-header-logo" />
                        <Button onClick={() => Dialog({view: 'headerAccount'})} color="primary" className="cs-header-button mbsc-bold" variant="flat" >
                            {configuration.displayName}
                        </Button>
                    </div>
                </div>
                <div className="mbsc-col-auto" >
                    <div className="mbsc-row cs-header-col-end">
                        <Button className="cs-header-button mbsc-bold" onClick={() => ButtonLocale()} variant="flat" >
                            <FontAwesomeIcon className="cs-header-icon" icon={faLanguage} /> {locale.header.c + (width > 576 ? locale.header.d : '')}
                        </Button>
                    </div>
                </div>
            </div>
            {dialog.view === 'headerAccount' &&
                <HeaderAccount
                    locale={locale}
                    dialog={dialog}
                    Dialog={Dialog}
                    width={width}
                />
            }
        </div>
    );
}

export default Header;
