import React, {useContext, useState, useCallback, useEffect, useRef} from 'react';
import {Button, SegmentedGroup, Segmented, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardWorkspaceText.css';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import {
    ClassicEditor,
    Alignment,
    Autosave,
    BlockQuote,
    Bold,
    Essentials,
    FindAndReplace,
    FontSize,
    GeneralHtmlSupport,
    Highlight,
    Indent,
    IndentBlock,
    Italic,
    List,
    ListProperties,
    Paragraph,
    RemoveFormat,
    Underline
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardWorkspaceText({locale, workspaceItem, setLoading, teamtalk, navigation}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [content, setContent] = useState(workspaceItem.data.content);
    const [menu, setMenu] = useState('original');
    const [editorInstance, setEditorInstance] = useState(null);
    const broadcast = useRef(null);

    const EditorReady = editor => {
        setEditorInstance(editor);
    };

    const EditorUpdate = data => {
        setContent(data);

        if (!broadcast.current && Empty(teamtalk) && data && data.length % 10 === 0) {

            EditorSave(data);
        } else if (!broadcast.current && !Empty(teamtalk) && data && data.length % 5 === 0) {

            EditorSave(data);
        } else if (broadcast.current) {

            broadcast.current = false;
        }
    }

    const EditorSave = data => {

        const message = {
            type: 'wss',
            path: 'text',
            action: 'update',
            data: {
                id: workspaceItem.id,
                content: data,
                teamtalk:  (!Empty(teamtalk) && teamtalk.id) ? {id: teamtalk.id, users: teamtalk.users} : null
            }
        };
        wsCall(message);
    }

    const ButtonUpdate = data => {
        setLoading(true);

        const message = {
            type: 'wss',
            path: 'workspace-item',
            action: 'update',
            data: {
                id: workspaceItem.id,
                teamtalk:  (!Empty(teamtalk) && teamtalk.id) ? {id: teamtalk.id, users: teamtalk.users} : null,
                tab: (!Empty(teamtalk) && teamtalk.id) ? null : navigation.tab
            }
        };
        if (data === 'accept') {
            message.data.accept = true;
        }
        if (data === 'reject') {
            message.data.reject = true;
        }
        wsCall(message);
    }

    const SegmentedUpdate = data => {
        setMenu(data.target.value);
    }

    const UpdateText = useCallback(data => {
        if (data.content) {
            broadcast.current = true;
            editorInstance.setData(data.content);
        }
    }, [editorInstance]);

    useEffect(() => {
        if (wsResponse &&
            !Empty(wsResponse) &&
            wsResponse.action === 'update' &&
            wsResponse.path === 'text' &&
            ((Empty(teamtalk) && !wsResponse.data.teamtalk) ||
                (!Empty(teamtalk) && wsResponse.data.teamtalk && teamtalk.id === wsResponse.data.teamtalk && wsResponse.data.id && wsResponse.data.id === workspaceItem.id))) {
            UpdateText(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateText, setWsResponse, teamtalk, workspaceItem]);

    return (
        <>
            {workspaceItem.update &&
                <div className="mbsc-row cs-dashboard-workspace-text-header-secondary" >
                    <div className="mbsc-col" />
                    <div className="mbsc-col-auto" >
                        <div className="mbsc-row cs-dashboard-workspace-text-col-end">
                            <Button color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-dashboard-workspace-text-color" onClick={() => ButtonUpdate('accept')} >
                                {locale.dashboardWorkspaceText.c}
                            </Button>
                            <Button color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-dashboard-workspace-text-color" onClick={() => ButtonUpdate('reject')} >
                                {locale.dashboardWorkspaceText.d}
                            </Button>
                        </div>
                    </div>
                </div>
            }
            {workspaceItem.update &&
                <div className="mbsc-row mbsc-justify-content-center cs-dashboard-workspace-text-section">
                    <div className="mbsc-col-12 mbsc-col-sm-12 mbsc-col-md-10 mbsc-col-lg-8 mbsc-col-xl-6">
                        <SegmentedGroup className="cs-dashboard-workspace-text-update" name="update" color="primary" value={menu} onChange={SegmentedUpdate}>
                            <Segmented value="original" >
                                {locale.dashboardWorkspaceText.a}
                            </Segmented>
                            <Segmented value="update" >
                                {locale.dashboardWorkspaceText.b}
                            </Segmented>
                        </SegmentedGroup>
                    </div>
                </div>
            }
            {!workspaceItem.update &&
                <div className="mbsc-row cs-dashboard-workspace-text-section">
                    <div className="mbsc-col cs-dashboard-workspace-text-editor">
                        <CKEditor
                            onBlur={() => EditorSave(content)}
                            editor={ClassicEditor}
                            config={{
                                toolbar: {
                                    items: ['findAndReplace',
                                        '|',
                                        'fontSize',
                                        '|',
                                        'bold',
                                        'italic',
                                        'underline',
                                        'removeFormat',
                                        '|',
                                        'highlight',
                                        'blockQuote',
                                        '|',
                                        'alignment',
                                        '|',
                                        'bulletedList',
                                        'numberedList',
                                        'outdent',
                                        'indent'],
                                    shouldNotGroupWhenFull: false
                                },
                                versionCheck: false,
                                plugins: [
                                    Alignment,
                                    Autosave,
                                    BlockQuote,
                                    Bold,
                                    Essentials,
                                    FindAndReplace,
                                    FontSize,
                                    GeneralHtmlSupport,
                                    Highlight,
                                    Indent,
                                    IndentBlock,
                                    Italic,
                                    List,
                                    ListProperties,
                                    Paragraph,
                                    RemoveFormat,
                                    Underline
                                ],
                                fontSize: {
                                    options: [10, 12, 14, 'default', 18, 20, 22],
                                    supportAllValues: true
                                },
                                htmlSupport: {
                                    allow: [
                                        {
                                            name: /^.*$/,
                                            styles: true,
                                            attributes: true,
                                            classes: true
                                        }
                                    ]
                                },
                                list: {
                                    properties: {
                                        styles: true,
                                        startIndex: true,
                                        reversed: true
                                    }
                                },
                                licenseKey: 'GPL',
                                ui: {
                                    poweredBy: {position:'inside', side:'right'}
                                }
                            }}
                            data={content}
                            onChange={(event, editor) => EditorUpdate(editor.getData())}
                            disableWatchdog={true}
                            onReady={EditorReady}
                        />
                    </div>
                </div>
            }
            {workspaceItem.update &&
                <div className="mbsc-row cs-dashboard-workspace-text-section">
                    <div className="mbsc-col cs-dashboard-workspace-text-editor">
                        <CKEditor
                            editor={ClassicEditor}
                            onReady={editor => {
                                editor.enableReadOnlyMode('editor-dashboard-text');
                            }}
                            config={{
                                toolbar: {
                                    items: [],
                                    shouldNotGroupWhenFull: false
                                },
                                versionCheck: false,
                                plugins: [
                                    Alignment,
                                    Autosave,
                                    BlockQuote,
                                    Bold,
                                    Essentials,
                                    FindAndReplace,
                                    FontSize,
                                    GeneralHtmlSupport,
                                    Highlight,
                                    Indent,
                                    IndentBlock,
                                    Italic,
                                    List,
                                    ListProperties,
                                    Paragraph,
                                    RemoveFormat,
                                    Underline
                                ],
                                fontSize: {
                                    options: [10, 12, 14, 'default', 18, 20, 22],
                                    supportAllValues: true
                                },
                                htmlSupport: {
                                    allow: [
                                        {
                                            name: /^.*$/,
                                            styles: true,
                                            attributes: true,
                                            classes: true
                                        }
                                    ]
                                },
                                list: {
                                    properties: {
                                        styles: true,
                                        startIndex: true,
                                        reversed: true
                                    }
                                },
                                licenseKey: 'GPL',
                                ui: {
                                    poweredBy: {position:'inside', side:'right'}
                                }
                            }}
                            data={menu === 'update' ? workspaceItem.data.update.content : content}
                            disableWatchdog={true}
                        />
                    </div>
                </div>
            }
        </>
    );
}

export default DashboardWorkspaceText;
