import React, {useCallback, useContext, useEffect} from 'react';
import {Button, Popup, Select, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderTools.css';
import Empty from "../utils/Empty";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderTools({Dialog, dialog, locale, configuration, functions, setFunctions, setToast, width, teamtalk}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ButtonClose = () => {
        DialogClose();
    }

    const SelectFunctions = data => {

        const message = {
            type: 'wss',
            path: 'conversation-function',
            action: 'update',
            data: {
                functions: data,
                teamtalk: (!Empty(teamtalk) && teamtalk.id) ? {id: teamtalk.id, users: teamtalk.users} : null
            }
        };
        wsCall(message);
    }

    const UpdateConversationFunction = useCallback(data => {
        if (data.toast) {
            setToast({message: locale.dashboardHeaderTools.b, color: 'info', display: 'bottom', duration: 1000});
        }
        if (data.functions) {
            setFunctions(data.functions);
        }
    }, [locale.dashboardHeaderTools.b]);

    useEffect(() => {
        if (wsResponse &&
            !Empty(wsResponse) &&
            wsResponse.action === 'update' &&
            wsResponse.path === 'conversation-function' &&
            ((Empty(teamtalk) && !wsResponse.data.teamtalk) ||
                (!Empty(teamtalk) && wsResponse.data.teamtalk && teamtalk.id === wsResponse.data.teamtalk))) {
            UpdateConversationFunction(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateConversationFunction, setWsResponse, teamtalk]);

    return (
        <Popup className="cs-dashboard-header-tools-popup" maxHeight={750} closeOnOverlayClick={false} closeOnEsc={false} scrollLock={false} display="center" width="600" buttons={[]} isOpen={dialog.view === 'dashboardHeaderTools'} onClose={DialogClose}>
            <div className="mbsc-grid cs-dashboard-header-tools-header-col">
                <div className="mbsc-row cs-dashboard-header-tools-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-dashboard-header-tools-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-dashboard-header-tools-header-col mbsc-align-center" : "mbsc-col cs-dashboard-header-tools-header-col"} >
                        <Button className="cs-dashboard-header-tools-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {locale.dashboardHeaderTools.c}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-dashboard-header-tools-header-col mbsc-align-right">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-header-tools-close-button" onClick={() => ButtonClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-dashboard-header-tools-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="cs-dashboard-header-tools-helper">
                <p>{locale.dashboardHeaderTools.d}</p>
            </div>
            <Select
                animation="slide-down"
                inputStyle="underline"
                dropdown={false}
                placeholder={locale.dashboardHeaderTools.a}
                labelStyle="floating"
                touchUi={false}
                rows={configuration.mobi.functions.length}
                display="inline"
                selectMultiple={true}
                value={functions}
                data={configuration.mobi.functions}
                onChange={args => SelectFunctions(args.value)}
            />
        </Popup>
    );
}

export default DashboardHeaderTools;
