import React, {useContext, useEffect, useCallback, useState} from 'react';
import {Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {NavItem, TabNav} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars, faPipe, faPlus, faUsersBetweenLines} from "@fortawesome/pro-duotone-svg-icons";
import '../styles/dashboardHeader.css';
import DashboardHeaderUser from "../dialogs/DashboardHeaderUser";
import DashboardHeaderConversations from "../dialogs/DashboardHeaderConversations";
import DashboardHeaderMenu from "../dialogs/DashboardHeaderMenu";
import DashboardHeaderTeamtalk from "../dialogs/DashboardHeaderTeamtalk";
import DashboardHeaderTeamtalkNew from "../dialogs/DashboardHeaderTeamtalkNew"
import DashboardHeaderTabRename from "../dialogs/DashboardHeaderTabRename";
import DashboardHeaderConversationDuplicate from "../dialogs/DashboardHeaderConversationDuplicate";
import DashboardHeaderConversationRename from "../dialogs/DashboardHeaderConversationRename";
import DashboardHeaderTabNew from "../dialogs/DashboardHeaderTabNew";
import DashboardHeaderTabs from "../dialogs/DashboardHeaderTabs";
import DashboardHeaderReset from "../dialogs/DashboardHeaderReset";
import DashboardHeaderMobile from "../dialogs/DashboardHeaderMobile";
import DashboardHeaderTools from "../dialogs/DashboardHeaderTools";
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeader({Navigation, navigation, width, locale, dialog, Dialog, configuration, user, Conversation, functions, toast, system, setLocale, setUser, token, setToast, tabs, setTabs, setMessages, setText, setWorkspace, model, setFunctions, teamtalk, setTeamtalk}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [archive, setArchive] = useState({});
    const [tab, setTab] = useState({});

    const Logout = useCallback(data => {
        const message = {
            type: 'wss',
            path: 'user-logout',
            action: 'update',
            data: data && data === 'all' ? {all: true} : {}
        };
        wsCall(message);
    }, [wsCall]);

    const NavTab = data => {
        if (!system && navigation.tab === data.id) {
            setTab(data);
            Dialog({view: 'dashboardHeaderTabRename'});
        } else if (!system && navigation.tab !== data.id) {
            setToast({message: locale.dashboardHeader.h, color: 'info', display: 'bottom', duration: 1000});
            setTeamtalk({});

            const message = {
                type: 'wss',
                path: 'tab',
                action: 'get',
                data: {
                    id: data.id,
                    tabs: tabs
                }
            };
            wsCall(message);
        }
    };

    const ButtonTab = data => {
        Dialog({view: 'dashboardHeaderTabNew'});
    };

    const ButtonDisabled = data => {
        let item = false;
        if (!Empty(toast) || system || (data && navigation.card === data)) {
            item = true;
        }
        return item;
    };

    const UpdateUserLogout = useCallback(data => {
        if (data.toast) {
            setToast({message: locale.dashboardHeader.f, color: 'info', display: 'bottom', duration: 3000});
            window.localStorage.removeItem('token');
            window.localStorage.text = '<p></p>';
            token.current = '';
            setUser({});
            Navigation({view: 'login'});
        }
    }, [Navigation, locale.dashboardHeader.f]);

    const UpdateUser = useCallback(data => {
        if (data.toast) {
            setToast({message: locale.dashboardHeader.j, color: 'info', display: 'bottom', duration: 1000});
        }
        if (data.user) {
            setUser(data.user);
        }
        if (dialog.view) {
            Dialog({view: 'close'});
        }
    }, [locale.dashboardHeader.j, Dialog, dialog.view]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'user') {
            UpdateUser(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateUser, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'user-logout') {
            UpdateUserLogout(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateUserLogout, setWsResponse]);

    useEffect(() => {
        if (wsResponse && wsResponse && !Empty(wsResponse) && wsResponse.data.expired) {
            Logout(null);
            setWsResponse({});
        }
    }, [wsResponse, setWsResponse, Logout]);

    return (
        <div className="mbsc-grid cs-dashboard-header-toolbar" >
            <div className="mbsc-row">
                <div className="mbsc-col" >
                    <div className="mbsc-row cs-dashboard-header-col-start">
                        {width > 576 &&
                            <div style={configuration.logo ? {backgroundImage: `url(${configuration.logo})`} : {backgroundImage: `url(${configuration.logoCS})`}} className={(width < 992 && !configuration.teamtalk) ? "cs-dashboard-header-logo-xs" : "cs-dashboard-header-logo"}  />
                        }
                        {width > 992 &&
                            <Button color="primary" variant="flat" className="cs-dashboard-header-color cs-dashboard-header-cursor mbsc-bold" >
                                {configuration.displayName}
                            </Button>
                        }
                    </div>
                </div>
                <div className="mbsc-col-auto" >
                    <div className="mbsc-row cs-dashboard-header-col-end">
                        {configuration.teamtalk &&
                            <Button disabled={ButtonDisabled()} color="primary" variant="flat" className={width < 576 ? "mbsc-bold cs-dashboard-header-color cs-dashboard-header-button-tabs" : "mbsc-bold cs-dashboard-header-color"} id="dashboardHeaderTeamtalk" title={locale.dashboardHeader.a} onClick={() => Dialog({view: 'dashboardHeaderTeamtalk'})}>
                                {width > 992 && <FontAwesomeIcon className="cs-dashboard-header-button-icon" icon={faUsersBetweenLines} />}<div className="cs-dashboard-header-menu-text">{(!Empty(teamtalk) && teamtalk.name) ? teamtalk.name : locale.dashboardHeader.a}</div>
                            </Button>
                        }
                        {configuration.teamtalk &&
                            <Button color="secondary" variant="flat" className="cs-dashboard-header-cursor" >
                                <FontAwesomeIcon className="cs-dashboard-header-button-icon" icon={faPipe} />
                            </Button>
                        }
                        <Button disabled={ButtonDisabled()} color="primary" variant="flat" className="mbsc-bold cs-dashboard-header-color" id="dashboardHeaderMenu" title={locale.dashboardHeader.e} onClick={() => Dialog({view: 'dashboardHeaderMenu'})} >
                            {width > 992 && '\u00A0'}<FontAwesomeIcon className="cs-dashboard-header-button-icon" icon={faBars} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="mbsc-row">
                <div className="mbsc-col-auto cs-dashboard-header-button-nav-left">
                    <Button color="primary" className="mbsc-bold cs-dashboard-header-color" variant="flat" title={locale.dashboardHeader.e} onClick={() => ButtonTab()} >
                        <FontAwesomeIcon className="cs-dashboard-header-button-icon" icon={faPlus} />
                    </Button>
                </div>
                <div className={width > 576 ? "mbsc-col cs-dashboard-header-button-nav-right" : "mbsc-col cs-dashboard-header-button-nav-right-small"} >
                    {width > 576 &&
                        <TabNav theme="ios" themeVariant="light" display='inline'>
                            {tabs && tabs.length !== 0 && tabs.map(item => {
                                return (
                                    <NavItem disabled={!Empty(toast) || system} selected={navigation.tab === item.id} onClick={(ev) => NavTab(item)} >
                                        <span className={navigation.tab === item.id ? 'mbsc-bold cs-dashboard-header-button-nav' : 'mbsc-bold'} >{item.name}</span>
                                    </NavItem>
                                );
                            })}
                        </TabNav>
                    }
                    {width < 576 &&
                        <Button id="dashboardHeaderTabs" className="mbsc-bold cs-dashboard-header-color cs-dashboard-header-button-tabs" color="primary" variant="flat" title={locale.dashboardHeader.i} onClick={() => Dialog({view: "dashboardHeaderTabs"})} >
                            {locale.dashboardHeader.i}
                        </Button>
                    }
                </div>
            </div>
            {dialog.view === 'dashboardHeaderUser' &&
                <DashboardHeaderUser
                    locale={locale}
                    dialog={dialog}
                    Dialog={Dialog}
                    user={user}
                    Logout={Logout}
                    setUser={setUser}
                    width={width}
                />
            }
            {dialog.view === 'dashboardHeaderConversationRename' &&
                <DashboardHeaderConversationRename
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    width={width}
                    archive={archive}
                />
            }
            {dialog.view === 'dashboardHeaderConversationDuplicate' &&
                <DashboardHeaderConversationDuplicate
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    width={width}
                    archive={archive}
                />
            }
            {dialog.view === 'dashboardHeaderConversations' &&
                <DashboardHeaderConversations
                    dialog={dialog}
                    Dialog={Dialog}
                    locale={locale}
                    Conversation={Conversation}
                    setToast={setToast}
                    width={width}
                    setArchive={setArchive}
                    navigation={navigation}
                />
            }
            {dialog.view === 'dashboardHeaderTabs' &&
                <DashboardHeaderTabs
                    dialog={dialog}
                    Dialog={Dialog}
                    tabs={tabs}
                    NavTab={NavTab}
                    width={width}
                    navigation={navigation}
                />
            }
            {dialog.view === 'dashboardHeaderMenu' &&
                <DashboardHeaderMenu
                    dialog={dialog}
                    Dialog={Dialog}
                    locale={locale}
                    Logout={Logout}
                    user={user}
                    configuration={configuration}
                    setLocale={setLocale}
                    setUser={setUser}
                    setToast={setToast}
                    Navigation={Navigation}
                    toast={toast}
                    system={system}
                    navigation={navigation}
                    functions={functions}
                    model={model}
                />
            }
            {dialog.view === 'dashboardHeaderTeamtalk' &&
                <DashboardHeaderTeamtalk
                    dialog={dialog}
                    Dialog={Dialog}
                    locale={locale}
                    setTeamtalk={setTeamtalk}
                />
            }
            {dialog.view === 'dashboardHeaderTabRename' &&
                <DashboardHeaderTabRename
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    tab={tab}
                    tabs={tabs}
                    setTabs={setTabs}
                    width={width}
                />
            }
            {dialog.view === 'dashboardHeaderTabNew' &&
                <DashboardHeaderTabNew
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    tabs={tabs}
                    setTabs={setTabs}
                    setMessages={setMessages}
                    setText={setText}
                    tab={navigation.tab}
                    Navigation={Navigation}
                    width={width}
                    setWorkspace={setWorkspace}
                    teamtalk={teamtalk}
                    setTeamtalk={setTeamtalk}
                />
            }
            {dialog.view === 'dashboardHeaderTeamtalkNew' &&
                <DashboardHeaderTeamtalkNew
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    setMessages={setMessages}
                    setText={setText}
                    Navigation={Navigation}
                    width={width}
                    setWorkspace={setWorkspace}
                    setTeamtalk={setTeamtalk}
                />
            }
            {dialog.view === 'dashboardHeaderReset' &&
                <DashboardHeaderReset
                    locale={locale}
                    dialog={dialog}
                    Dialog={Dialog}
                    width={width}
                />
            }
            {dialog.view === 'dashboardHeaderMobile' &&
                <DashboardHeaderMobile
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    configuration={configuration}
                    width={width}
                    toast={toast}
                />
            }
            {dialog.view === 'dashboardHeaderTools' &&
                <DashboardHeaderTools
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    configuration={configuration}
                    functions={functions}
                    setFunctions={setFunctions}
                    setToast={setToast}
                    width={width}
                    teamtalk={teamtalk}
                />
            }
        </div>
    );
}

export default DashboardHeader;
