import React, {useCallback, useContext, useState} from 'react';
import {Popup, Input, setOptions, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardGridRename.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import {NetworkContext} from "../utils/NetworkContext";
import Empty from "../utils/Empty";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardGridRename({Dialog, dialog, locale, setRefresh, id, setToast, width, update, columns, teamtalk}) {
    const {wsCall} = useContext(NetworkContext);
    const [name, setName] = useState(() => {
        const itemName = columns.find(item => item.key === update.key);

        return itemName.name;
    });

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ItemSave = () => {
        if (!name) {
            setToast({message: locale.dashboardGridRename.c, color: 'danger', display: 'bottom', duration: 3000});
        } else if (name) {
            const itemUpdate = update;
            itemUpdate.name = name;
            itemUpdate.id = id;
            itemUpdate.teamtalk =  (!Empty(teamtalk) && teamtalk.id) ? {id: teamtalk.id, users: teamtalk.users} : null;

            setRefresh(true);

            const message = {
                type: 'wss',
                path: 'grid-column-name',
                action: 'update',
                data: itemUpdate
            };
            wsCall(message);
        }
    }

    const ButtonClose = () => {
        const itemName = columns.find(item => item.key === update.key);

        if (name !== itemName.name) {
            ItemSave();
        } else {
            DialogClose();
        }
    }

    return (
        <Popup className="cs-dashboard-grid-rename-popup" width={600} display="center" scrollLock={true} closeOnOverlayClick={false} closeOnEsc={false} buttons={[]} isOpen={dialog.view === ('dashboardGridRename')} onClose={DialogClose}>
            <div className="mbsc-grid cs-dashboard-grid-rename-header-col">
                <div className="mbsc-row cs-dashboard-grid-rename-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-dashboard-grid-rename-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-dashboard-grid-rename-header-col mbsc-align-center" : "mbsc-col cs-dashboard-grid-rename-header-col"} >
                        <Button className="cs-dashboard-grid-rename-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {locale.dashboardGridRename.e}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-dashboard-grid-rename-header-col mbsc-align-right">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-grid-rename-close-button" onClick={() => ButtonClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-dashboard-grid-rename-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <Input inputStyle="underline" label={locale.dashboardGridRename.b} labelStyle="floating" type="text" name="account" value={name} onChange={ev => setName(ev.target.value)} />
        </Popup>
    );
}

export default DashboardGridRename;
