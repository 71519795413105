import React, {useCallback, useContext, useRef} from 'react';
import {Popup, Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/dashboardGridAdd.css';
import {NetworkContext} from "../utils/NetworkContext";
import Empty from "../utils/Empty";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardGridAdd({Dialog, dialog, locale, setRefresh, id, update, setUpdate, teamtalk}) {
    const {wsCall} = useContext(NetworkContext);
    const menu = useRef([{above: true}, {below: true}, {right: true}, {left: true}]);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ListItem = data => {
        let item = '';

        if (data.item.above && update.type === 'row') {
            item = <li key={data.item.timestamp} className="cs-dashboard-grid-add-li">
                <Button color="primary" variant="flat" className="cs-dashboard-grid-add-button mbsc-bold" onClick={() => ButtonUpdate('above')} >
                    {locale.dashboardGridAdd.a}
                </Button>
            </li>;
        } else if (data.item.below && update.type === 'row') {
            item = <li key={data.item.timestamp} className="cs-dashboard-grid-add-li">
                <Button color="primary" variant="flat" className="cs-dashboard-grid-add-button mbsc-bold" onClick={() => ButtonUpdate('below')} >
                    {locale.dashboardGridAdd.b}
                </Button>
            </li>;
        } else if (data.item.right && update.type === 'column') {
            item = <li key={data.item.timestamp} className="cs-dashboard-grid-add-li">
                <Button color="primary" variant="flat" className="cs-dashboard-grid-add-button mbsc-bold" onClick={() => ButtonUpdate('right')} >
                    {locale.dashboardGridAdd.c}
                </Button>
            </li>;
        } else if (data.item.left && update.type === 'column') {
            item = <li key={data.item.timestamp} className="cs-dashboard-grid-add-li">
                <Button color="primary" variant="flat" className="cs-dashboard-grid-add-button mbsc-bold" onClick={() => ButtonUpdate('left')} >
                    {locale.dashboardGridAdd.d}
                </Button>
            </li>;
        }

        return item;
    }

    const ButtonUpdate = data => {
        const itemUpdate = update;
        itemUpdate.location = data;
        itemUpdate.id = id;
        itemUpdate.teamtalk =  (!Empty(teamtalk) && teamtalk.id) ? {id: teamtalk.id, users: teamtalk.users} : null

        if (update.type === 'column') {
            setUpdate(itemUpdate);
            Dialog({view: 'dashboardGridColumnNew'});
        } else {
            setRefresh(true);

            const message = {
                type: 'wss',
                path: 'grid-add',
                action: 'update',
                data: itemUpdate
            };
            wsCall(message);
        }
    }

    return (
        <Popup className="cs-dashboard-grid-add-popup" display="anchored" anchor={document.getElementById(('dashboardGridAdd'))} showArrow={false} buttons={[]} isOpen={dialog.view === ('dashboardGridAdd')} onClose={DialogClose} >
            <Listview
                theme="ios"
                themeVariant="light"
                select="single"
                itemType={ListItem}
                data={menu.current}
            />
        </Popup>
    );
}

export default DashboardGridAdd;
