import React, {useCallback, useEffect, useState, useContext} from 'react';
import {Popup, confirm, setOptions, Button, Input} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderConversations.css';
import dayjs from "dayjs";
import Empty from "../utils/Empty";
import Truncate from "../utils/Truncate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark, faArrowUpShortWide, faArrowDownWideShort} from "@fortawesome/pro-duotone-svg-icons";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderConversations({locale, dialog, Dialog, Conversation, setToast, width, setArchive, navigation}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [conversations, setConversations] = useState([]);
    const [results, setResults] = useState([]);
    const [text, setText] = useState(null);
    const [instance, setInstance] = useState({});
    const [target, setTarget] = useState(null);
    const [sort, setSort] = useState(true);

    const DialogClose = useCallback(() => {
        setConversations([]);
        Dialog({view: 'close'});
    }, [Dialog]);

    const ButtonSort = () => {
        if (sort && results.length !== 0) {
            const array = results;
            array.sort((a, b) => Number(a.timestamp) - Number(b.timestamp));
            setResults(array);
        } else if (!sort && results.length !== 0) {
            const array = results;
            array.sort((a, b) => Number(b.timestamp) - Number(a.timestamp));
            setResults(array);
        }
        if (sort && conversations.length !== 0) {
            const array = conversations;
            array.sort((a, b) => Number(a.timestamp) - Number(b.timestamp));
            setConversations(array);
        } else if (!sort && conversations.length !== 0) {
            const array = conversations;
            array.sort((a, b) => Number(b.timestamp) - Number(a.timestamp));
            setConversations(array);
        }
        if (sort) {
            setSort(false);
        } else {
            setSort(true);
        }
    }

    const Conversations = () => {
        const message = {
            type: 'wss',
            path: 'conversation-archives',
            action: 'get',
            data: {}
        };
        wsCall(message);
    }

    const ListItem = data => {
        const itemDate = dayjs(parseInt(data.item.timestamp)).format("MMMM D, YYYY @ h:mm A");

        return <li data-id={data.item.timestamp} >
            <div className="mbsc-bold mbsc-txt-muted">{width < 992 ? Truncate(data.item.text, 15) : Truncate(data.item.text, 30)}</div>
            <div className="mbsc-row mbsc-txt-muted mbsc-txt-s cs-dashboard-header-conversations-item-txt">
                {itemDate}
            </div>
        </li>;
    }

    const ListTap = data => {
        if (text) {
            setTarget(results[data.index].timestamp);
        } else {
            setTarget(conversations[data.index].timestamp);
        }
    }

    const InputSuggestion = data => {
        setText(data);
        const itemValue = data;
        let itemResults = conversations;

        if (itemValue && itemResults.length !== 0) {
            itemResults = itemResults.filter(item =>
                item.text.toLowerCase().includes(itemValue.toLowerCase())
            );
        }
        if (!itemValue && itemResults.length === 0){
            setResults([]);
        } else {
            setResults(itemResults);
        }
    };

    const GetConversationArchives = useCallback(data => {
        if (data.conversations) {
            setConversations(data.conversations);
        }
    }, []);

    const DeleteConversationArchive = useCallback(data => {
        if (data.toast) {
            setToast({message: locale.dashboardHeaderConversations.a, color: 'info', display: 'bottom', duration: 1000});
        }
        if (data.conversations) {
            setConversations(data.conversations);
        }
    }, [locale.dashboardHeaderConversations.a]);

    const GetConversationArchive = useCallback(data => {
        if (data.toast) {
            Conversation();
            DialogClose();
        }
    }, [Conversation, DialogClose]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'conversation-archives') {
            GetConversationArchives(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetConversationArchives, setWsResponse]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'conversation-archive') {
            DeleteConversationArchive(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeleteConversationArchive, setWsResponse]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'conversation-archive') {
            GetConversationArchive(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetConversationArchive, setWsResponse]);

    useEffect(() => {
        if (target) {
            instance.openActions(target, 'left');
        }
    }, [instance, target]);

    useEffect(() => {
        Conversations();
    }, []);

    return (
        <Popup className="cs-dashboard-header-conversations-popup" scrollLock={false} closeOnOverlayClick={false} closeOnEsc={false} fullScreen={true} display="center" maxHeight="700" buttons={[]} isOpen={dialog.view === 'dashboardHeaderConversations'} onClose={DialogClose}>
            <div className="mbsc-grid cs-dashboard-header-conversations-header-col">
                <div className="mbsc-row cs-dashboard-header-conversations-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-dashboard-header-conversations-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-dashboard-header-conversations-header-col mbsc-align-center" : "mbsc-col cs-dashboard-header-conversations-header-col"} >
                        <Button className="cs-dashboard-header-conversations-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {locale.dashboardHeaderConversations.b}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-dashboard-header-conversations-header-col mbsc-align-right">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-header-conversations-close-button" onClick={() => DialogClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-dashboard-header-conversations-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="mbsc-grid cs-dashboard-header-conversations-header-col">
                <div className="mbsc-row cs-dashboard-header-conversations-header-row">
                    <div className="mbsc-col cs-dashboard-header-conversations-header-col cs-dashboard-header-conversations-select">
                        <Input
                            inputStyle="underline"
                            placeholder={locale.dashboardHeaderConversations.m}
                            labelStyle="floating"
                            type="text"
                            name="search"
                            value={text}
                            onChange={ev => InputSuggestion(ev.target.value)}
                        />
                    </div>
                    <div className="mbsc-col-auto cs-dashboard-header-conversations-col-sort">
                        <Button className="mbsc-bold" variant="flat" onClick={() => ButtonSort()} >
                            <FontAwesomeIcon className="cs-dashboard-header-conversations-sort-button" icon={sort ? faArrowDownWideShort : faArrowUpShortWide} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="cs-dashboard-header-conversations-scroll">
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={ListItem}
                    data={text ? results : conversations}
                    onItemTap={ListTap}
                    onInit={(event, inst) => setInstance(inst)}
                    actionsWidth={100}
                    actions={{
                        right: [{
                            color: '#0B7DBB',
                            text: locale.dashboardHeaderConversations.k,
                            action: (event, inst) => {
                                const itemConvo = conversations[event.index];
                                setArchive(itemConvo);
                                Dialog({view: 'dashboardHeaderConversationRename'});

                                inst.close(event.target);
                                return false;
                            }
                        }, {
                            color: '#0B7DBB',
                            text: locale.dashboardHeaderConversations.l,
                            action: (event, inst) => {
                                const itemConvo = conversations[event.index];
                                setArchive(itemConvo);
                                Dialog({view: 'dashboardHeaderConversationDuplicate'});

                                return false;
                            }
                        }, {
                            color: '#0B7DBB',
                            text: locale.dashboardHeaderConversations.c,
                            action: (event, inst) => {
                                confirm({
                                    title: locale.dashboardHeaderConversations.h,
                                    message: locale.dashboardHeaderConversations.i,
                                    okText: locale.dashboardHeaderConversations.c,
                                    cancelText: locale.dashboardHeaderConversations.g,
                                    callback: (res) => {
                                        if (res) {
                                            const item = conversations[event.index].timestamp;

                                            const message = {
                                                type: 'wss',
                                                path: 'conversation-archive',
                                                action: 'delete',
                                                data: {
                                                    conversation: item
                                                }
                                            };
                                            wsCall(message);
                                        } else {
                                            inst.close(event.target);
                                        }
                                    }
                                });
                                return false;
                            }
                        }, {
                            color: '#0B7DBB',
                            text: locale.dashboardHeaderConversations.j,
                            action: (event, inst) => {
                                confirm({
                                    title: locale.dashboardHeaderConversations.d,
                                    message: locale.dashboardHeaderConversations.e,
                                    okText: locale.dashboardHeaderConversations.f,
                                    cancelText: locale.dashboardHeaderConversations.g,
                                    callback: (res) => {
                                        if (res) {
                                            const item = conversations[event.index].timestamp;

                                            const message = {
                                                type: 'wss',
                                                path: 'conversation-archive',
                                                action: 'get',
                                                data: {
                                                    conversation: item,
                                                    tab: navigation.tab
                                                }
                                            };
                                            wsCall(message);
                                        } else {
                                            inst.close(event.target);
                                        }
                                    }
                                });
                                return false;
                            }
                        }]
                    }}
                />
            </div>
        </Popup>
    );
}

export default DashboardHeaderConversations;
