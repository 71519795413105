import React, {useCallback, useContext, useRef} from 'react';
import {Popup, Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/dashboardGridMove.css';
import {NetworkContext} from "../utils/NetworkContext";
import Empty from "../utils/Empty";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardGridMove({Dialog, dialog, locale, id, update, rows, columns, setRefresh, teamtalk}) {
    const {wsCall} = useContext(NetworkContext);
    const menu = useRef([{above: true}, {below: true}, {right: true}, {left: true}]);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ListItem = data => {
        let item = '';

        if (data.item.above && update.type === 'row' && update.key !== 1) {
            item = <li key={data.item.timestamp} className="cs-dashboard-grid-move-li">
                <Button color="primary" variant="flat" className="cs-dashboard-grid-move-button mbsc-bold" onClick={() => ButtonUpdate('above')} >
                    {locale.dashboardGridMove.a}
                </Button>
            </li>;
        } else if (data.item.below && update.type === 'row' && update.key !== rows) {
            item = <li key={data.item.timestamp} className="cs-dashboard-grid-move-li">
                <Button color="primary" variant="flat" className="cs-dashboard-grid-move-button mbsc-bold" onClick={() => ButtonUpdate('below')} >
                    {locale.dashboardGridMove.b}
                </Button>
            </li>;
        } else if (data.item.right && update.type === 'column' && columns.findIndex(col => col.key === update.key) !== (columns.length - 1)) {
            item = <li key={data.item.timestamp} className="cs-dashboard-grid-move-li">
                <Button color="primary" variant="flat" className="cs-dashboard-grid-move-button mbsc-bold" onClick={() => ButtonUpdate('right')} >
                    {locale.dashboardGridMove.c}
                </Button>
            </li>;
        } else if (data.item.left && update.type === 'column' && columns.findIndex(col => col.key === update.key) !== 1) {
            item = <li key={data.item.timestamp} className="cs-dashboard-grid-move-li">
                <Button color="primary" variant="flat" className="cs-dashboard-grid-move-button mbsc-bold" onClick={() => ButtonUpdate('left')} >
                    {locale.dashboardGridMove.d}
                </Button>
            </li>;
        }

        return item;
    }

    const ButtonUpdate = data => {
        setRefresh(true);

        const itemUpdate = update;
        itemUpdate.location = data;
        itemUpdate.id = id;
        itemUpdate.teamtalk =  (!Empty(teamtalk) && teamtalk.id) ? {id: teamtalk.id, users: teamtalk.users} : null;

        const message = {
            type: 'wss',
            path: 'grid-move',
            action: 'update',
            data: itemUpdate
        };
        wsCall(message);
    }

    return (
        <Popup className="cs-dashboard-grid-move-popup" display="anchored" anchor={document.getElementById(('dashboardGridMove'))} showArrow={false} buttons={[]} isOpen={dialog.view === ('dashboardGridMove')} onClose={DialogClose} >
            <Listview
                theme="ios"
                themeVariant="light"
                select="single"
                itemType={ListItem}
                data={menu.current}
            />
        </Popup>
    );
}

export default DashboardGridMove;
