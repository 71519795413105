import React, {useContext, useState, useCallback, useEffect, useRef} from 'react';
import {Button, SegmentedGroup, Segmented, setOptions, Textarea} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardWorkspaceCode.css';
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardWorkspaceCode({locale, workspaceItem, setLoading, teamtalk, navigation}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [content, setContent] = useState(workspaceItem.data.content);
    const [menu, setMenu] = useState('original');
    const broadcast = useRef(false);

    const EditorUpdate = data => {
        setContent(data);

        if (!broadcast.current && Empty(teamtalk) && data && data.length % 10 === 0) {

            EditorSave(data);
        } else if (!broadcast.current && !Empty(teamtalk) && data && data.length % 5 === 0) {

            EditorSave(data);
        } else if (broadcast.current) {

            broadcast.current = false;
        }
    }

    const EditorSave = data => {

        const message = {
            type: 'wss',
            path: 'text',
            action: 'update',
            data: {
                id: workspaceItem.id,
                content: data,
                teamtalk:  (!Empty(teamtalk) && teamtalk.id) ? {id: teamtalk.id, users: teamtalk.users} : null
            }
        };
        wsCall(message);
    }

    const ButtonUpdate = data => {
        setLoading(true);

        const message = {
            type: 'wss',
            path: 'workspace-item',
            action: 'update',
            data: {
                id: workspaceItem.id,
                teamtalk:  (!Empty(teamtalk) && teamtalk.id) ? {id: teamtalk.id, users: teamtalk.users} : null,
                tab: (!Empty(teamtalk) && teamtalk.id) ? null : navigation.tab
            }
        };
        if (data === 'accept') {
            message.data.accept = true;
        }
        if (data === 'reject') {
            message.data.reject = true;
        }
        wsCall(message);
    }

    const SegmentedUpdate = data => {
        setMenu(data.target.value);
    }

    const UpdateText = useCallback(data => {
        if (data.content){
            broadcast.current = true;
            setContent(data.content);
        }
    }, []);

    useEffect(() => {
        if (wsResponse &&
            !Empty(wsResponse) &&
            wsResponse.action === 'update' &&
            wsResponse.path === 'text' &&
            ((Empty(teamtalk) && !wsResponse.data.teamtalk) ||
                (!Empty(teamtalk) && wsResponse.data.teamtalk && teamtalk.id === wsResponse.data.teamtalk && wsResponse.data.id && wsResponse.data.id === workspaceItem.id))) {
            UpdateText(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateText, setWsResponse, teamtalk, workspaceItem]);

    return (
        <>
            {workspaceItem.update &&
                <div className="mbsc-row cs-dashboard-workspace-code-header-secondary" >
                    <div className="mbsc-col" />
                    <div className="mbsc-col-auto" >
                        <div className="mbsc-row cs-dashboard-workspace-code-col-end">
                            <Button color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-dashboard-workspace-code-color" onClick={() => ButtonUpdate('accept')} >
                                {locale.dashboardWorkspaceCode.c}
                            </Button>
                            <Button color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-dashboard-workspace-code-color" onClick={() => ButtonUpdate('reject')} >
                                {locale.dashboardWorkspaceCode.d}
                            </Button>
                        </div>
                    </div>
                </div>
            }
            {workspaceItem.update &&
                <div className="mbsc-row mbsc-justify-content-center cs-dashboard-workspace-code-section">
                    <div className="mbsc-col-12 mbsc-col-sm-12 mbsc-col-md-10 mbsc-col-lg-8 mbsc-col-xl-6">
                        <SegmentedGroup className="cs-dashboard-workspace-code-update" name="update" color="primary" value={menu} onChange={SegmentedUpdate}>
                            <Segmented value="original" >
                                {locale.dashboardWorkspaceCode.a}
                            </Segmented>
                            <Segmented value="update" >
                                {locale.dashboardWorkspaceCode.b}
                            </Segmented>
                        </SegmentedGroup>
                    </div>
                </div>
            }
            {!workspaceItem.update &&
                <div className="mbsc-row cs-dashboard-workspace-code-section">
                    <div className="mbsc-col cs-dashboard-workspace-code-editor">
                        <Textarea
                            className="cs-dashboard-workspace-code-editor-text"
                            inputStyle="underline"
                            rows={1000}
                            value={content}
                            onChange={ev => EditorUpdate(ev.target.value)}
                            onBlur={() => EditorSave(content)}
                            onKeyDown={(e) => {
                                if (e.key === 'Tab') {
                                    e.preventDefault();
                                    const start = e.target.selectionStart;
                                    const end = e.target.selectionEnd;

                                    const newText = content.substring(0, start) + '\t' + content.substring(end);
                                    EditorUpdate(newText);

                                    setTimeout(() => {
                                        e.target.selectionStart = e.target.selectionEnd = start + 1;
                                    }, 0);
                                }
                            }}
                        />
                    </div>
                </div>
            }
            {workspaceItem.update &&
                <div className="mbsc-row cs-dashboard-workspace-code-section">
                    <div className="mbsc-col cs-dashboard-workspace-code-editor">
                        <Textarea
                            disabled
                            className="cs-dashboard-workspace-code-editor-text"
                            inputStyle="underline"
                            rows={1000}
                            value={menu === 'update' ? workspaceItem.data.update.content : content}
                        />
                    </div>
                </div>
            }
        </>
    );
}

export default DashboardWorkspaceCode;
